<template>

    <div>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="4"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6"
                        class="ml-auto">
                        <div class="d-flex">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Buscar personal ..."
                                trim
                            />

                            <BButtonStudents :course-slug="courseSlug" />

                        </div>
                    </b-col>

                </b-row>

            </div>

            <b-table
                ref="refInscriptionsListTable"
                class="position-relative table-inscriptions"
                :style="totalInscriptions === 1 ? 'height: 200px' : 'height: inherit'"
                :items="fetchCourseInscriptions"
                responsive
                :fields="tableColumns"
                primary-key="id2"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc"
            >

                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

                <template #cell(student)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar
                                size="32"
                                :src="null"
                                :text="avatarText(data.item.fullName)"
                                variant="light-success"
                            />
                        </template>
                        <span class="font-weight-bold text-primary d-block text-nowrap">
                            {{ data.item.fullName }}
                        </span>
                    </b-media>
                </template>

                <template #cell(user_email)="data">
                    <span class="d-block text-nowrap">
                        {{ data.item.user_email }}
                    </span>
                </template>

                <template #cell(amount)="data">
					<span class="font-weight-bold d-block text-nowrap">
						{{ `S/. ${parseFloat(data.item.amount).toFixed(2)}` }}
                    </span>
				</template>

                <template #cell(createdAt)="data">
                    <span class="d-block text-nowrap">
                        {{ moment(data.item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
                    </span>
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalInscriptions"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>

    </div>

</template>

<script>

    import BButtonStudents from '@core/components/b-button-students/BButtonStudents.vue';
    import useCourseInscriptionsList from './useCourseInscriptionsList';
	import { avatarText } from '@core/utils/filter';
    import Ripple from 'vue-ripple-directive';
	import vSelect from 'vue-select';
	import moment from 'moment';

	export default {
		components: {
            BButtonStudents,

			vSelect
		},
        props: {
            courseData: {
                type: Object,
                required: true
            }
        },
        directives: {
            Ripple
        },
		data() {
            return {
                moment
            }
        },
		setup(props) {

			// REFS
            const courseSlug = props.courseData.slug;

			const {
				fetchCourseInscriptions,
				tableColumns,
				perPage,
				currentPage,
				totalInscriptions,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refInscriptionsListTable,
				refetchData
			} = useCourseInscriptionsList(courseSlug);

			return {
				fetchCourseInscriptions,
				tableColumns,
				perPage,
				currentPage,
				totalInscriptions,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refInscriptionsListTable,
				refetchData,

                // PROPS
                courseSlug,

				// ACTIONS
				avatarText
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
        align-items: center;
    }

	.per-page-selector {
		width: 90px;
	}

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>
