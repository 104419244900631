<template>
    <b-row>
        <b-col md="12">
            <b-card footer-tag="footer">

                <b-row>

                    <!-- CODE -->
                    <b-col
                        md="12"
                    >
                        <b-form-group
                            label="CÓDIGO"
                            label-for="code"
                        >
                            <b-form-input
                                id="code"
                                v-model="formData.code"
                                class="font-weight-bold text-primary"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- NAME -->
                    <b-col
                        cols="12"
                    >
                        <b-form-group
                            label="NOMBRE DEL SERVICIO"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="formData.name"
                                class="font-weight-bold"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- MINIMUM SCORE -->
                    <b-col
                        cols="12"
                        md="6"
                    >
                        <b-form-group
                            label="NOTA MÍNIMA APROBATORIA"
                            label-for="minimum_score"
                        >
                            <b-form-input
                                id="minimum_score"
                                type="number"
                                v-model="formData.minimum_score"
                                class="font-weight-bold"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- AMOUNT -->
                    <b-col
                        cols="12"
                        md="6"
                    >
                        <b-form-group
                            label="MONTO EN SOLES"
                            label-for="amount"
                        >
                            <b-form-input
                                id="amount"
                                type="number"
                                step=".01"
                                v-model="formData.amount"
                                class="font-weight-bold"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- COURSE TYPE -->
                    <b-col
                        cols="12"
                    >
                        <b-form-group
                            label="TIPO DE SERVICIO"
                            label-for="course_type"
                        >
                            <b-form-input
                                id="course_type"
                                v-model="formData.course_type"
                                class="font-weight-bold"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- COURSE TYPE: SINCRÓNICO -->
                    <template v-if="formData.course_type_id === 1">

                        <!-- CLASS LINK -->
                        <b-col
                            cols="12"
                        >
                            <b-form-group
                                label="LINK DE LA CLASE"
                                label-for="class_link"
                            >
                                <b-input-group class="input-group-merge">
                                    <b-form-input
                                        id="class_link"
                                        v-model="formData.class_link"
                                        class="font-weight-bold"
                                        disabled
                                        readonly
                                    />
                                    <b-input-group-append>
                                        <b-button
                                            variant="primary"
                                            v-clipboard:copy="formData.class_link"
                                            v-clipboard:success="onCopy"
                                        >
                                            <feather-icon icon="ClipboardIcon" />
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <!-- CLASS DATIME -->
                        <b-col
                            cols="12"
                        >
                            <b-form-group
                                label="FECHA Y HORA DE LA CLASE"
                                label-for="class_datetime"
                            >
                                <b-form-input
                                    id="class_datetime"
                                    v-model="formData.class_datetime"
                                    class="font-weight-bold text-success"
                                    disabled
                                    readonly
                                />
                            </b-form-group>
                        </b-col>

                    </template>

                    <!-- COURSE TYPE: ASINCRÓNICO -->
                    <template v-if="formData.course_type_id === 2">

                        <b-col
                            v-if="formData.induction_vimeo_video"
                            cols="12"
                            md="6"
                            class="mb-2 m-auto"
                        >

                            <div class="embed-responsive embed-responsive-4by3">

                                <vimeo-player
                                    class="embed-responsive-item"
                                    ref="player"
                                    :video-id="formData.induction_vimeo_video"
                                />

                            </div>

                        </b-col>

                    </template>

                    <!-- DESCRIPTION -->
                    <b-col cols="12">
                        <b-form-group
                            label="DESCRIPCIÓN"
                            label-for="description"
                        >
                            <quill-editor
                                id="description"
                                style="min-height: 120px;"
                                v-model="formData.description"
                                :options="snowOption"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                </b-row>

            </b-card>
        </b-col>
    </b-row>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ref, onBeforeMount } from '@vue/composition-api';
    import { useToast } from 'vue-toastification/composition';
    import { quillEditor } from 'vue-quill-editor';
    import vueVimeoPlayer from 'vue-vimeo-player';
    import VueClipboard from 'vue-clipboard2';
    import moment from 'moment';
    import Vue from 'vue';

    Vue.use(vueVimeoPlayer);
    Vue.use(VueClipboard);

    export default {
        components: {
            quillEditor
        },
		props: {
            courseData: {
                type: Object,
                required: true
            }
        },
		setup(props) {

            // USE TOAST
			const toast = useToast();

			// REFS
            const courseData = ref(props.courseData);

			const formData = ref({
                code: null,
				name: null,
                course_type_id: null,
                course_type: null,
                induction_vimeo_video: null,
                class_link: null,
                class_datetime: null,
				description: null,
				minimum_score: null,
				amount: null
			});

            const snowOption = ref({
                theme: 'snow'
            });

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
               setData();
            });

            const setData = () => {

                const class_datetime = courseData.value.class_datetime ? moment(courseData.value.class_datetime).format('DD/MM/YYYY HH:mm:ss') : null;

                formData.value = {
                    code: courseData.value.code,
                    name: courseData.value.name,
                    description: courseData.value.description,
                    minimum_score: courseData.value.minimum_score,
                    induction_vimeo_video: courseData.value.induction_vimeo_video,
                    class_link: courseData.value.class_link,
                    class_datetime,
                    amount: courseData.value.amount,
                    course_type_id: courseData.value.course_type.id,
                    course_type: courseData.value.course_type.name
                }

            };

            const onCopy = () => {

                toast({
                    component: ToastificationContent,
                    props: {
                        title: '¡Enlace copiado!',
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                });

            };

			return {
				// REFS
                snowOption,
                formData,

                // METHODS
                onCopy
			}
		}
    }

</script>

<style scoped>

    .font-weight-bold {
        font-weight: bold !important;
    }

</style>

<style>

    .ql-container, .ql-editor {
        min-height: inherit;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/quill.scss';
</style>
