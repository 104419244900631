import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCourses(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/contractors/courses', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchCourse(ctx, { slug }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/contractors/courses/${slug}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchInscriptions(ctx, { slug, queryParams }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/contractors/courses/${slug}/inscriptions`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchPreferencePayment(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/contractors/courses/${id}/inscriptions/mercadopago/request`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
    }
}