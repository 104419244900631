'use strict'
import * as dotenv from 'dotenv'
import { loadMercadoPago } from "@mercadopago/sdk-js";
dotenv.config()

/* 
  @ts-nocheck
  declare const MercadoPago: any 
*/


loadMercadoPago();

const PUBLIC_KEY = process.env.VUE_APP_MP_PUBLIC_KEY
const LOCALE = "es-PE"
let mercadoPago

/**
 * Starts the payment process using the provided preference ID.
 *
 * @param {string} preferenceId - The ID of the preference for the payment.
 * @return {Promise} A promise that resolves when the payment is successfully opened.
 */
export const startThePayment = async (preferenceId) => {
  if(!mercadoPago) await loadMercadoPago().then(() => {
    try {
      mercadoPago = new window.MercadoPago(PUBLIC_KEY, {
        locale: LOCALE
      })
    } catch (error) {
      console.log('Error al crear mercado pago: ', error)
    }
  })
  
  try {
    const payment = mercadoPago.checkout({
      preference: {
        id: preferenceId
      },
      theme: {
        elementsColor: '#DD242D',
        headerColor: '#DD242D'
      }
    })
    return payment.open()
  } catch (error) {
    console.error('Algun tipo de error con MercadoPago', error)
  }
}
