<template>

    <b-col
        cols="auto"
    >

        <b-button
            @click="openModal()"
            variant="primary"
            class="ml-auto"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        >
            <span class="text-nowrap">Agregar Inscripción(es)</span>
        </b-button>

        <!-- MODAL -->
        <b-modal
            ref="modal"
            size="xl"
            centered
            title-tag="div"
            header-bg-variant="primary"
            header-text-variant="white"
            title="INSCRIPCIÓN DEL PERSONAL DE EMPRESA CONTRATISTA"
            @hide="closeModal"
            no-close-on-backdrop
            no-close-on-esc
        >
            <b-alert variant="info" show>
                <div class="alert-body">
                    <ul class="mb-0">
                        <li>
                            Seleccione el personal de la empresa contratista que se incluirán en la inscripción <strong>(después del respectivo pago)</strong>.
                        </li>
                    </ul>
                </div>
            </b-alert>

            <b-card-text class="my-2">

                <!-- SORTING  -->
                <b-form-group
                    label="Ordenar"
                    label-size="sm"
                    label-align-sm="left"
                    label-cols-sm="2"
                    label-for="sortBySelect"
                    class="mb-md-0"
                >
                    <b-input-group
                        size="sm"
                    >
                        <b-form-select
                            id="sortBySelect"
                            v-model="sortBy"
                            :options="sortOptions"
                        >
                            <template #first>
                                <option value="">Ninguno</option>
                            </template>
                        </b-form-select>
                        <b-form-select
                            v-model="sortDesc"
                            size="sm"
                            :disabled="!sortBy"
                        >
                            <option :value="false">ASC</option>
                            <option :value="true">DESC</option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>

                <!-- FILTER -->
                <b-form-group
                    label="Fitrar"
                    label-cols-sm="2"
                    label-align-sm="left"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0 mt-1"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Buscar ..."
                        />
                        <b-input-group-append>
                            <b-button
                                :disabled="!filter"
                                @click="filter = ''"
                            >
                                Limpiar
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <b-row
                    align-v="center"
                    align-h="between"
                >
                    <b-col
                        cols="3"
                        class="my-1"
                    >
                        <b-form-group class="mb-0">
                            <label class="d-inline-block text-sm-left mr-50">Por página</label>
                            <b-form-select
                                id="perPageSelect"
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                                class="w-50"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- TABLE -->
                <b-table
                    ref="table"
                    class="position-relative"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="students"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    striped
                    hover
                    responsive
                    show-empty
                    empty-filtered-text="No hay registros que coincidan con su filtro"
                    empty-text="No se encontraron registros coincidentes"
                >

                    <template #cell(#)="row">
                        <b-form-checkbox
                            v-model="markedStudents"
                            :value="row.item"
                            class="p-0"
                        >
                        </b-form-checkbox>
                    </template>

                    <template #cell(code)="data">
                        <span class="font-weight-bold d-block text-nowrap">
                            {{ data.item.code }}
                        </span>
                    </template>

                    <template #cell(question)="data">
                        <span class="d-block text-nowrap">
                            {{ data.item.question.length > 90 ? `${data.item.question.substring(0, 90)}...` : data.item.question }}
                        </span>
                    </template>

                    <template #cell(question_type_name)="data">
                        <span class="d-block text-nowrap">
                            <b-badge :variant="data.item.question_type_color">
                                {{ data.item.question_type_name }}
                            </b-badge>
                        </span>
                    </template>

                </b-table>

                <!-- PAGINATION -->
                <div>

                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>

                </div>

            </b-card-text>

            <template #modal-footer="{ cancel }">

                <b-button-loading
                    text="PAGAR INSCRIPCIONES"
                    :processing="processing"
                    @process-action="payInscriptions">
                </b-button-loading>

                <b-button variant="outline-secondary" @click="cancel">
                    CERRAR
                </b-button>

            </template>
        </b-modal>

    </b-col>

</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import courseStoreModule from '@/views/modules/contractors/courses/courseStoreModule.js';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ref, onBeforeMount, computed, onUnmounted } from '@vue/composition-api';
    import { useToast } from 'vue-toastification/composition';
    import { startThePayment } from '@/libs/mercado-pago';
    import Ripple from 'vue-ripple-directive';
    import axios from '@axios';
    import store from '@/store';

    export default {
        directives: {
            Ripple
        },
        props: {
            courseSlug: {
                type: String,
                required: true
            }
        },
        components: {
            BButtonLoading
        },
        setup(props) {

            // USE TOAST
			const toast = useToast();

            // REFS
            const course = ref(props.courseSlug);
            const processing = ref(false);

            const loading = ref(true);
            const students = ref([]);
            const modal = ref(null);

            const markedStudents = ref([]);
            const fields = ref([]);

            const totalRows = ref(1);
			const currentPage = ref(1);
			const perPage = ref(10);
			const pageOptions = ref([5, 10, 15, 20]);
			const sortBy = ref('');
			const sortDesc = ref(false);
			const sortDirection = ref('asc');
			const filter = ref(null);
			const filterOn = ref([]);


            const COURSE_APP_STORE_MODULE_NAME = 'app-course';

			// REGISTER MODULE
			if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.registerModule(COURSE_APP_STORE_MODULE_NAME, courseStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME);
			});

            onBeforeMount( () => {

                const styleColum = {
                    thClass: 'text-center',
                    tdClass: 'text-center'
                };

                fields.value = [{
                    label: '#',
                    key: '#',
                    ...styleColum
                }, {
                    label: 'PERSONAL EMPRESA CONTRATISTA',
                    sortable: true,
                    key: 'fullName',
                    ...styleColum
                }, {
                    label: 'NÚMERO DE DOCUMENTO',
                    sortable: true,
                    key: 'document_number',
                    ...styleColum
                }, {
                    label: 'TELÉFONO CELULAR',
                    sortable: true,
                    key: 'phone',
                    ...styleColum
                }, {
                    label: 'CORREO ELECTRÓNICO',
                    sortable: true,
                    key: 'email',
                    ...styleColum
                }];

                loading.value = false;

            });

            // METHODS
            const openModal = async () => {

				loading.value = true;

                await getStudents();

				totalRows.value = students.value.length;
				currentPage.value = 1;
				sortBy.value = '';
				sortDesc.value = false;
				sortDirection.value = 'asc';
				filter.value = null;

				modal.value.show();

			};

            const sortOptions = computed(() => {
                return fields.value
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            });

            const onFiltered = (filteredItems) => {
                totalRows.value = filteredItems.length;
                currentPage.value = 1;
            };

            const getStudents = async () =>
            {
				const { data } = await axios.get(`/contractors/courses/${course.value}/students`);

                students.value = data.students;
			};

            const closeModal = () => {

                markedStudents.value = [];
				modal.value.hide();

                loading.value = false;

            }

            const payInscriptions = async () => {

                if(markedStudents.value.length === 0)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'No hay inscripciones marcadas.',
                            icon: 'CheckIcon',
                            variant: 'warning'
                        }
                    });
                }

                processing.value = true;

                try {

                    const { data } = await axios.post(`/contractors/courses/${course.value}/inscriptions/mercadopago/request`, { students:  markedStudents.value.map(s => s.id)} );
                    const preferenceId = data.preference_id;
                    startThePayment(preferenceId);

                } catch (err) {

                    const message = err.response.data.message ? err.response.data.message : 'Error al cargar la pasarela de pago.';

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });

                } finally {
                    processing.value = false;
                }

            };

            return {
                // REFS
                markedStudents,
                loading,
                modal,
                fields,
                students,
                course,
                processing,
                toast,

                totalRows,
                currentPage,
                perPage,
                pageOptions,
                sortBy,
                sortDesc,
                sortDirection,
                filter,
                filterOn,

                // METHODS
                payInscriptions,
                sortOptions,
                onFiltered,
                openModal,
                closeModal
            }
        },
        methods: {
            sendQPToBack() {
                const fullPath = this.$route.fullPath
                const queryParams = fullPath.split('?')
                this.$router.replace('?');

                axios.get(`/contractors/courses/${this.course}/inscriptions/mercadopago/create?${queryParams[1]}`)
                    .then((response) => {

                        const data = response.data;

                        if (data.status && data.status == 'approved') {

                            this.toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Se realizó el pago correctamente.',
                                    icon: 'CheckIcon',
                                    variant: 'success'
                                }
                            });

                        } else if (data.status && data.status == 'pending') {

                            this.toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Pago pendiente de completar.',
                                    icon: 'InfoIcon',
                                    variant: 'info'
                                }
                            });

                        } else {

                            this.toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Problemas al procesar el pago.',
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            });

                        }

                    })
                    .catch((err) => {
                        const response = err.response;

                        if (response) {
                            const data = response.data;

                            if (data && !data.success)
                            {
                                this.toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error en reconocer el pago.',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            }
                        }
                    })
                    .finally(() => this.mercadoPQueryParams = null);

            },
            verifyQueryParams() {
                this.mercadoPQueryParams = this.$route.query
                if (this.mercadoPQueryParams.payment_id) {
                    this.sendQPToBack()
                }
            },
        },
        created() {
            this.verifyQueryParams()
        },
    }

</script>
