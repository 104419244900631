<template>
	<component :is="'div'">

		<!-- Alert: No item found -->
		<b-alert
            variant="danger"
            :show="courseData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos del servicio</h4>
            <div class="alert-body">
                No se encontró ningún servicio con este id. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'contractors-courses-list'}"
                >
                    Lista de servicios
                </b-link>
                para buscar otro servicio.
            </div>
        </b-alert>

		<b-tabs
			v-if="courseData"
			pills
		>

			<!-- Tab: General Information -->
			<b-tab :active="tabGeneralInformationActive">

				<template #title>
					<feather-icon
						icon="InfoIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Información general</span>
				</template>

				<course-tab-general-information
					class="mt-2 pt-75"
					:course-data="courseData"
				/>

			</b-tab>

			<!-- Tab: Inscriptions -->
			<b-tab :active="tabIncriptionsActive">

				<template #title>
					<feather-icon
						icon="UsersIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Inscritos</span>
				</template>

				<course-tab-inscriptions
					class="mt-2 pt-75"
					:course-data="courseData"
				/>

			</b-tab>

		</b-tabs>

	</component>
</template>

<script>

	import CourseTabGeneralInformation from '../courses-tabs/tab-general-information/CourseTabGeneralInformation';
	import CourseTabInscriptions from '../courses-tabs/tab-inscriptions/CourseTabInscriptionsList';
	import { ref, onMounted, onUnmounted } from '@vue/composition-api';
	import courseStoreModule from '../courseStoreModule';
	import router from '@/router';
	import store from '@/store';

	export default {
		components: {
			CourseTabGeneralInformation,
			CourseTabInscriptions
		},
		setup() {

			// REFS
            const tabGeneralInformationActive = ref(true);
            const tabIncriptionsActive = ref(false);

			const courseData = ref(null);

			const COURSE_APP_STORE_MODULE_NAME = 'app-course'

			// REGISTER MODULE
			if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.registerModule(COURSE_APP_STORE_MODULE_NAME, courseStoreModule)

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME)
			});

			onMounted( () => {

				if (router.currentRoute.query.tab && router.currentRoute.query.tab === 'generalinformation')
				{
					tabIncriptionsActive.value = false;
					tabGeneralInformationActive.value = true;
				}

				if (router.currentRoute.query.tab && router.currentRoute.query.tab === 'inscriptions')
				{
					tabGeneralInformationActive.value = false;
					tabIncriptionsActive.value = true;
				}

			});

			store.dispatch('app-course/fetchCourse', { slug: router.currentRoute.params.slug })
				.then(response => {
					if (response.data === 'error') {
                        courseData.value = undefined
                    } else {
                        courseData.value = response.data.course;
                    }
				})
				.catch(error => {
					if (error.response.status === 404) {
						courseData.value = undefined
					}
				});

			return {
				// DATA
                tabGeneralInformationActive,
                tabIncriptionsActive,

				courseData
			}
		}
	}

</script>
